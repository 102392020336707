/// <reference types="@types/segment-analytics" />

import { BrowserUtils } from '@/src/utils/BrowserUtils'

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
    getSegmentAnalyticsInstance: () => Promise<SegmentAnalytics.AnalyticsJS>
    readyOtherAnalytics: () => void
    sendAnalyticsUsingFullEventName: (eventName: string, options?: Record<string, unknown>) => void
  }
}

export function getSegmentAnalyticsInstance(): Promise<SegmentAnalytics.AnalyticsJS> {
  return new Promise((res, rej) => {
    let numberOfRetries = 100
    const delayTime = 100

    if (window.analytics) {
      return res(window.analytics)
    }
    const interval = setInterval(() => {
      numberOfRetries--
      if (window.analytics) {
        clearInterval(interval)
        return res(window.analytics)
      }
      if (numberOfRetries < 0) {
        clearInterval(interval)
        rej('Unable to load Segment')
      }
    }, delayTime)
  })
}

/**  
Pattern

Context => Object => Action

The format answers these questions:

Where is the event from? Context
What is the event effecting? Object
What was the action taken? Action
Some examples of how this might look in various contexts:

App => site => deployed
App => function => invoked
Site => docs => rated
Site => docs => searched
CLI => user => loggedIn
API => user => passwordReset

Syntax: "Product Name: Object Name: Action Description: e.g "Frontend: Desktop Rich Editor: Page TTS Button Clicked"
Use snake_case for event metadata e.g {first_name: 'Ravi'}
*
* See The Data Taxonomy Guide Here: https://www.notion.so/native/resemble/Data-Taxonomy-05717d123a7547928d873bec8c3b2390?pvs=6&utm_content=05717d12-3a75-4792-8d87-3bec8c3b2390&utm_campaign=TFJ2Y4T7D&deepLinkOpenNewTab=true
*/
export function sendAnalytics(context: string, object: string, action: string, options?: Record<string, unknown>) {
  const eventName = `${context}: ${object}: ${action}`
  getSegmentAnalyticsInstance().then((stAntcs) => {
    stAntcs.track(eventName, options)
  })
}

export function sendAnalyticsBeacon(fullEventName: string, metaData?: Record<string, string>) {
  const fd = new FormData()
  const token = (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content
  fd.append('authenticity_token', token)
  fd.append('eventName', fullEventName)

  if (metaData) {
    fd.append('properties', JSON.stringify(metaData))
  }

  navigator.sendBeacon('/analytics', fd)
}

export function sendAnalyticsUsingFullEventName(eventName: string, options?: Record<string, unknown>) {
  getSegmentAnalyticsInstance().then((stAntcs) => {
    stAntcs.track(eventName, options)
  })
}

export function sendDesktopRichEditorAnalytics(action: string, options?: Record<string, unknown>) {
  sendAnalytics('Frontend', 'Desktop Rich Editor', action, options)
}

export function sendVoiceCloningAnalytics(action: string, options?: Record<string, unknown>) {
  sendAnalytics('Frontend', 'Voice Cloning Page', action, options)
}

export function sendMobileEditorAnalytics(action: string, options?: Record<string, unknown>) {
  sendAnalytics('Frontend', 'Mobile Editor', action, options)
}

export function sendDesktopSSMLEditorAnalytics(action: string, options?: Record<string, unknown>) {
  sendAnalytics('Frontend', 'Desktop SSML Editor', action, options)
}

export function sendRecorderAnalytics(action: string, options?: Record<string, unknown>) {
  const isMobile = BrowserUtils.isMobile()
  sendAnalytics('Frontend', `${isMobile ? 'Mobile' : 'Desktop'} Recorder`, action, options)
}

export function sendDesktopRecorderAnalytics(action: string, options?: Record<string, unknown>) {
  sendAnalytics('Frontend', 'Desktop Recorder', action, options)
}

export function sendMarketplaceAnalytics(action: string, options?: Record<string, unknown>) {
  sendAnalytics('Frontend', 'Marketplace Page', action, options)
}

export function sendMobileRecorderAnalytics(action: string, options?: Record<string, unknown>) {
  sendAnalytics('Frontend', 'Mobile Recorder', action, options)
}

export function sendDesktopEditorAnalytics(action: string, options?: Record<string, unknown>) {
  sendAnalytics('Frontend', 'Desktop Editor', action, options)
}

export function sendTopHeaderAnalytics(action: string, options?: Record<string, unknown>) {
  const isMobile = BrowserUtils.isMobile()
  sendAnalytics('Frontend', `${isMobile ? 'Mobile' : 'Desktop'} Top Header`, action, {
    ...options,
  })
}

// /projects
export function sendProjectsPageAnalytics(subpage: string, action: string, options?: Record<string, unknown>) {
  const isMobile = BrowserUtils.isMobile()
  sendAnalytics('Frontend', `${isMobile ? 'Mobile' : 'Desktop'} Projects ${subpage} Page`, action, {
    ...options,
  })
}

// /voices
export function sendVoicesPageAnalytics(subpage: string, action: string, options?: Record<string, unknown>) {
  const isMobile = BrowserUtils.isMobile()
  sendAnalytics('Frontend', `${isMobile ? 'Mobile' : 'Desktop'} Voices ${subpage} Page`, action, {
    ...options,
  })
}

// Frontend (Desktop | Mobile) Top Header (action), {...options}
function readyTopHeaderAntcs() {
  function readyDropdownAntcs() {
    const dropdown = document.querySelector('.dropdown-menu')
    if (dropdown !== null) {
      dropdown.addEventListener('click', (e) => {
        if (e.target !== null && e.target instanceof HTMLAnchorElement) {
          const dropdownItem = e.target as HTMLAnchorElement
          sendTopHeaderAnalytics('Dropdown Item Clicked', {
            item_clicked: dropdownItem.innerText,
          })
        }
      })
    }
  }

  const buildVoiceBtn = document.querySelector('#header a[href="/voices"]')
  if (buildVoiceBtn !== null) {
    buildVoiceBtn.addEventListener('click', () => {
      sendTopHeaderAnalytics('Build Voice Btn Clicked')
    })
  }

  const searchProjectsBtn = document.querySelector('#header button[type="submit"]')
  if (searchProjectsBtn !== null) {
    searchProjectsBtn.addEventListener('click', () => {
      sendTopHeaderAnalytics('Search Clip Btn Clicked')
    })
  }
  readyDropdownAntcs()
}

function readyProjectsPageAntcs() {
  // /projects
  const projectOwnersIcons = document.querySelectorAll('#projects .avatar')
  if (projectOwnersIcons.length > 0) {
    projectOwnersIcons.forEach((i) => {
      const avatar = i as HTMLSpanElement
      avatar.addEventListener('mouseenter', () => {
        sendProjectsPageAnalytics('Home', 'Project Owners Icon Hovered')
      })
    })
  }

  const teamMembersIcon = document.querySelectorAll('#projects .shared-icon')
  if (teamMembersIcon.length > 0) {
    teamMembersIcon.forEach((i) => {
      const avatar = i as HTMLSpanElement
      avatar.addEventListener('mouseenter', () => {
        sendProjectsPageAnalytics('Home', 'Shared With All Icon Hovered')
      })
    })
  }

  // /projects/../edit
  const editProjectOwnersBtn = document.querySelector('button[data-target="#InviteMembers"]') as HTMLButtonElement
  if (editProjectOwnersBtn !== null) {
    editProjectOwnersBtn.addEventListener('click', () => {
      sendProjectsPageAnalytics('Edit', 'Edit Project Owners Modal Opened')
    })
  }

  const saveMembersListBtn = document.querySelector('.modal-footer input[data-disable-with="Save"]')
  if (saveMembersListBtn !== null) {
    saveMembersListBtn.addEventListener('click', () => {
      sendProjectsPageAnalytics('Edit', 'Project Members List Updated')
    })
  }
}

function readyPhonemesPageAntcs() {
  const applyAllBtn = document.querySelector('input[value="Apply All"]')
  if (applyAllBtn !== null) {
    applyAllBtn.addEventListener('click', () => {
      sendAnalyticsUsingFullEventName('Frontend: Phonemes Page: Apply All Btn Clicked')
    })
  }

  const newPhonemeBtn = document.querySelector('input[value="+ New Phoneme"]')
  if (newPhonemeBtn !== null) {
    newPhonemeBtn.addEventListener('click', () => {
      sendAnalyticsUsingFullEventName('Frontend: Phonemes Page: New Phoneme Btn Clicked')
    })
  }

  const createOrUpdatePhonemeBtn = document.querySelector('input[value$="Phoneme"]') as HTMLInputElement
  if (createOrUpdatePhonemeBtn !== null) {
    createOrUpdatePhonemeBtn.addEventListener('click', () => {
      sendAnalyticsUsingFullEventName(`Frontend: Phonemes Page: ${createOrUpdatePhonemeBtn.value} Btn Clicked`)
    })
  }

  const tableRowBtns = document.querySelectorAll('td > a') as unknown as HTMLElement[]
  if (tableRowBtns.length > 0) {
    tableRowBtns.forEach((el) => {
      el.addEventListener('click', () => {
        sendAnalyticsUsingFullEventName(`Frontend: Phonemes Page: Phonemes Table ${el.dataset.method && el.dataset.method === 'delete' ? 'Delete' : el.innerText} Btn Clicked`)
      })
    })
  }
}

function readyTermSubstitutionsPageAntcs() {
  const applyAllBtn = document.querySelector('input[value="Apply All"]')
  if (applyAllBtn !== null) {
    applyAllBtn.addEventListener('click', () => {
      sendAnalyticsUsingFullEventName('Frontend: Substitutions Page: Apply All Btn Clicked')
    })
  }

  const newSubstitutionBtn = document.querySelector('input[value="+ New Substitution"]')
  if (newSubstitutionBtn !== null) {
    newSubstitutionBtn.addEventListener('click', () => {
      sendAnalyticsUsingFullEventName('Frontend: Substitutions Page: New Substitution Btn Clicked')
    })
  }

  const createOrUpdateSubstitutionBtn = document.querySelector('input[value$="Term substitution"]') as HTMLInputElement
  if (createOrUpdateSubstitutionBtn !== null) {
    createOrUpdateSubstitutionBtn.addEventListener('click', () => {
      sendAnalyticsUsingFullEventName(`Frontend: Substitutions Page: ${createOrUpdateSubstitutionBtn.value} Btn Clicked`)
    })
  }

  const tableRowBtns = document.querySelectorAll('td > a') as unknown as HTMLElement[]
  if (tableRowBtns.length > 0) {
    tableRowBtns.forEach((el) => {
      el.addEventListener('click', () => {
        sendAnalyticsUsingFullEventName(`Frontend: Substitutions Page: Substitutions Table ${el.dataset.method && el.dataset.method === 'delete' ? 'Delete' : el.innerText} Btn Clicked`)
      })
    })
  }
}

function readyVoicesPageAntcs() {
  const browserMarketPlaceBtn = document.querySelector('.voices-actions')

  if (browserMarketPlaceBtn !== null) {
    browserMarketPlaceBtn.addEventListener('click', (e) => {
      if (e.target instanceof HTMLInputElement) {
        sendVoicesPageAnalytics('Home', `${e.target.value} Btn Clicked`)
      }
    })
  }

  const voicesTable = document.querySelector('.voices-table table')
  if (voicesTable !== null) {
    voicesTable.addEventListener('click', (e) => {
      if (e.target instanceof HTMLAnchorElement) {
        const btn = e.target as HTMLAnchorElement
        sendVoicesPageAnalytics('Home', 'Voices Table Btn Clicked', {
          clicked_button: btn.innerText,
        })
      }
    })
  }
}
function readyOtherAnalytics() {
  readyTopHeaderAntcs()

  const pathname = window.location.pathname

  if (pathname.startsWith('/projects')) {
    readyProjectsPageAntcs()
  } else if (pathname.startsWith('/voices')) {
    readyVoicesPageAntcs()
  } else if (pathname.includes('phonemes')) {
    readyPhonemesPageAntcs()
  } else if (pathname.includes('term_substitutions')) {
    readyTermSubstitutionsPageAntcs()
  }
}

window.getSegmentAnalyticsInstance = getSegmentAnalyticsInstance
window.readyOtherAnalytics = readyOtherAnalytics
window.sendAnalyticsUsingFullEventName = sendAnalyticsUsingFullEventName
